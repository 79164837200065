<script lang="ts" setup>
import { asDisplayableName, getInitials } from '~/lib/helpers'

const props = withDefaults(defineProps<{
  src?: string | null
  name?: string | null
  alt?: string | null
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  srDescription?: string
}>(), {
  size: 'md'
})

const textSizeClass = computed(() => {
  return {
    xs: 'text-xs',
    sm: 'text-xs',
    md: 'text-base',
    lg: 'text-md',
    xl: 'text-lg'
  }[props.size]
})

const wrapperSizeClass = computed(() => {
  return {
    xs: 'size-6',
    sm: 'size-8',
    md: 'size-10',
    lg: 'size-12',
    xl: 'size-16'
  }[props.size]
})

const formattedName = computed(() => {
  if (props.name) {
    return getInitials(asDisplayableName(props.name))
  }
  return ''
})

const mode = useColorMode()

const bgColor = computed(() => {
  return mode.value === 'dark' ? 'bg-primary/90 border-primary hover:bg-primary' : 'bg-primary/40 hover:bg-primary/60 border-primary'
})
</script>

<template>
  <button size="icon" class="rounded-full aspect-square flex items-center justify-center cursor-pointer shadow-none" :class="wrapperSizeClass">
    <NuxtImg v-if="props.src" :src="props.src" :alt=" alt || name || srDescription || ''" class="rounded-full" />
    <div v-else class="w-full h-full flex items-center justify-center rounded-full aspect-square" :class="`${bgColor} ${wrapperSizeClass}`">
      <span :class="`${textSizeClass}`">{{ formattedName }}</span>
    </div>
    <span class="sr-only">{{ srDescription || name }}</span>
  </button>
</template>
