<script setup lang="ts">
import { Menu } from 'lucide-vue-next'
import { Button } from '@/components/ui/button'
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet'
import { Toaster } from '@/components/ui/sonner'
import { ScrollArea } from '~/components/ui/scroll-area'

const route = useRoute()
</script>

<template>
  <ScrollArea class="flex h-screen w-screen flex-col  relative">
    <header class="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6 z-10">
      <nav
        class="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6"
      >
        <NuxtLink to="/" class="flex items-center gap-2 text-lg font-semibold md:text-base w-10">
          <NuxtImg src="/logo_pink.svg" /><span class="font-extrabold text-xl">FeaTrack</span>
          <span class="sr-only">Featrack</span>
        </NuxtLink>
        <!--  <NuxtLink to="/dashboard" class="text-muted-foreground transition-colors hover:text-foreground">
          Dashboard
        </NuxtLink>
        <NuxtLink to="/dashboard/features" class="text-muted-foreground transition-colors hover:text-foreground">
          Features
        </NuxtLink>
        <NuxtLink to="/dashboard/plans" class="text-muted-foreground transition-colors hover:text-foreground">
          Plans
        </NuxtLink>
        <NuxtLink to="/dashboard/customers" class="text-muted-foreground transition-colors hover:text-foreground">
          Customers
        </NuxtLink> -->
        <!-- <a href="#" class="text-muted-foreground transition-colors hover:text-foreground">
          Analytics
        </a> -->
      </nav>
      <Sheet>
        <SheetTrigger as-child>
          <Button variant="outline" size="icon" class="shrink-0 md:hidden">
            <Menu class="h-5 w-5" />
            <span class="sr-only">Toggle navigation menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left">
          <nav class="grid gap-6 text-lg font-medium">
            <a href="#" class="flex items-center gap-2 text-lg font-semibold w-10">
              <NuxtImg src="/logo_pink.svg" />

              <span class="sr-only">FeaTrack</span>
            </a>

            <!--             <NuxtLink to="/dashboard" class="text-muted-foreground hover:text-foreground">
              Dashboard
            </NuxtLink>
            <NuxtLink to="/dashboard/features" class="text-muted-foreground hover:text-foreground">
              Features
            </NuxtLink>
            <NuxtLink to="/dashboard/plans" class="text-muted-foreground hover:text-foreground">
              Plans
            </NuxtLink>
            <NuxtLink to="/dashboard/customers" class="text-muted-foreground hover:text-foreground">
              Customers
            </NuxtLink> -->
            <!-- <a href="#" class="text-muted-foreground hover:text-foreground">
              Analytics
            </a> -->
          </nav>
        </SheetContent>
      </Sheet>
      <div class="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
        <form class="ml-auto flex-1 sm:flex-initial">
          <!-- <div class="relative">
            <Search class="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input type="search" placeholder="Search products..." class="pl-8 sm:w-[300px] md:w-[200px] lg:w-[300px]" />
          </div> -->
        </form>
        <NuxtLink to="/dashboard/integrations" as-child>
          <Badge>
            <iconify-icon icon="lucide:code-xml" />
            <span class="ml-1 hidden sm:block">How to Integrate</span>
          </Badge>
        </NuxtLink>
        <ClientOnly>
          <AppThemeSwitcher />
        </ClientOnly>
        <ClientOnly>
          <UserDropdown />
        </ClientOnly>
      </div>
    </header>

    <AppAnnoncementsBanner />
    <main class="flex flex-1 flex-col gap-4 container p-4 md:gap-8 md:p-8 ">
      <div class="flex  justify-between">
        <div>
          <slot name="header-start">
            <div class="flex gap-x-4">
              <div v-if="route.meta.backLink">
                <NuxtLink :to="route.meta.backLink">
                  <AppButton icon="lucide:arrow-left" variant="outline" size="icon" />
                </NuxtLink>
              </div>
              <div v-if="route.meta.title || route.meta.description">
                <h1 class="text-2xl font-semibold">
                  {{ route.meta.title }}
                </h1>
                <p class="text-muted-foreground">
                  {{ route.meta.description }}
                </p>
              </div>
              <div v-if="route.meta.badge">
                <Badge variant="outline">
                  {{ route.meta.badge }}
                </Badge>
              </div>
            </div>
          </slot>
        </div>
        <div class="">
          <slot name="header-end" />
        </div>
      </div>
      <div class="">
        <slot />
      </div>
    </main>
    <Toaster />
  </ScrollArea>
</template>
