<script setup lang="ts">
const { data, signOut } = useAuth()
</script>

<template>
  <DropdownMenu>
    <DropdownMenuTrigger as-child>
      <AppNameAvatar v-if="data?.user" sr-description="your user avatar" :name="data?.user?.name || data?.user?.email" :src="data?.user?.image" alt="Your avatar" />
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end">
      <DropdownMenuLabel>
        <div class="flex flex-col gap-y-1">
          <span>{{ data?.user?.name || data?.user?.email }}</span>
          <span v-if="data?.user?.name" class="text-muted-foreground">{{ data?.user?.email }}</span>
        </div>
      </DropdownMenuLabel>

      <DropdownMenuSeparator />
      <NuxtLink to="/dashboard/account" as-child>
        <DropdownMenuItem>Settings</DropdownMenuItem>
      </NuxtLink>
      <DropdownMenuItem>Support</DropdownMenuItem>
      <DropdownMenuSeparator />
      <DropdownMenuItem @click="signOut">
        Logout
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
</template>

<style scoped></style>
